// 顶部导航栏整体逻辑
import LogOn from '../../components/LogOn'
import SignUp from '../../components/SignUp.vue'

export default {
  components: { LogOn, SignUp },
  data () {
    return {
      isVisible: false, // 1、顶部搜索栏默认值
      isClicked: false, // 2、点击收藏按钮设置背景默认
      isLoginVisible: false, // 3、登录弹窗遮罩
      currentLoginType: 'logon', // 4、登录/注册标签切换
      isLoggedIn: false, // 用户登录状态

      page: 'home',
      route: {
        path: this.$route.path // 判断路由显示不同LOGO
      }
    }
  },

  // 【顶部搜索栏】
  created () {
    // 判断除首页路由，其他页面固定显示顶部搜索
    const route = this.$route.path
    if (route === '/') {
      this.isVisible = false
    } else {
      this.isVisible = true
    }
    // 在组件创建时获取页面的滚动高度
    window.addEventListener('scroll', this.handleScroll)
  },

  unmounted () {
    // 在组件销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll)
  },

  currentLoginComponent () {
    return this.currentLoginType === 'logon' ? 'LogOn' : 'SignUp'
  },

  // 【登录注册】处理登录和注册标签切换
  computed: {
    currentLoginComponent () {
      return this.currentLoginType === 'logon' ? 'LogOn' : 'SignUp'
    }
  },

  methods: {
    // 【顶部搜索】滚动屏幕显示顶部搜索框
    handleScroll () {
      const route = this.$route
      // 【顶部搜索】判断是否为首页并是否滚动到顶部
      if (window.pageYOffset > 380 && route.path === '/') {
        this.isVisible = true
      } else if (route.path !== '/') {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },

    // 【登录注册】处理弹窗遮罩方法
    openLogin () {
      this.isLoginVisible = true
      // 【登录注册】在打开弹窗时禁止页面滚动
      document.body.style.overflow = 'hidden'
    },
    closeLogin () {
      this.isLoginVisible = false
      // 【登录注册】在关闭弹窗时恢复页面滚动
      document.body.style.overflow = 'auto'
    },

    // 【登录注册】处理登录和注册标签切换
    switchLoginType (type) {
      this.currentLoginType = type
    }
  }
}
