<template>
    <div class="widget_large_main" @click.stop v-if="imageData">
      <!-- <p @click="hideSelf">[esc关闭]</p> -->
      <img src="../assets/images/icon_white_close.svg" @click="hideSelf">
      <div class="widget_large_img" ref="container">
        <img :src="imageData.image">
      </div>
      <div class="widget_large_content">
        <!-- 右侧头部信息 -->
        <div class="widget_popup_top">
          <div class="widget_price_box">
            <div
              v-if="imageData.price > 0"
              class="widget_popup_price">
                {{ imageData.price }}
                <img src="../assets/images/icon_candy.svg">
            </div>
            <div v-else class="widget_popup_price">
              免费
            </div>
            <div class="collectbox">
              <!-- <img title="举报/投诉" style="margin-right: .22rem;" src="../assets/images/icon_report.svg"> -->
              <img title="取消收藏" @click="toggleCollect(imageData._id)" @click.stop v-if="isCollected" src="../assets/images/icon_collect_hover.svg">
              <img title="收藏" @click="toggleCollect(imageData._id)" @click.stop v-else src="../assets/images/icon_collect.svg">
            </div>
          </div>
          <div class="widget_popup_title">
            <span v-if="imageData.price > 0">说明：复制该组件消耗的U糖归创作者所有</span>
            <router-link :to="{ name: 'widgetdetails', params: { id: imageData._id } }" target="_blank">
              <h1 title="详情">{{ imageData.title }}</h1>
            </router-link>
          </div>
          <div class="widget_popup_btn">
            <button
              class="large_copy_btn"
              v-if="(!imageData.author || imageData.author._id !== userInfo._id) && imageData.price > 0"
              @click="copyCodeLarge(imageData)"
              @click.stop>复制组件 {{ imageData.price }}🍬U糖
            </button>
            <button
              class="large_copy_btn"
              v-else
              @click="copyCodeLarge(imageData)"
              @click.stop>复制组件
            </button>
            <button class="large_share_btn" @click="copyUrl(imageData)">复制链接</button>
          </div>
          <div v-if="imageData.author" class="widget_popup_author">
            <router-link :to="{ name: 'userspace', params: { id: imageData.author._id } }" target="_blank">
              <div class="widget_popup_avatar"><img :src="imageData.author.imgUrl"></div>
              <span class="widget_author_name">{{ imageData.author.nickname }}</span>
              <span v-if="imageData.author.about" class="widget_author_signature">{{ imageData.author.about }}</span>
              <span v-else class="widget_author_signature">神秘的旅行者</span>
            </router-link>
          </div>
          <div v-else class="widget_popup_author">
            <div class="widget_popup_avatar"><img src="../assets/images/img_about_logo.png"></div>
            <span class="widget_author_name">UI大师官方</span>
            <span class="widget_author_signature">丰富的UI组件库，复制粘贴使用</span>
          </div>
        </div>
          <!-- 组件标签 -->
          <div class="widgettagsbox" v-if="imageData.tags && imageData.tags.length > 0">
            <span>组件标签</span>
            <div class="tagsbox">
              <div
                @click="searchByKeyword(tag)"
                class="tags_item"
                v-for="tag in imageData.tags"
                :key="tag">{{ tag }}
              </div>
            </div>
          </div>
          <!-- 相似组件 -->
          <div class="similarmain" v-if="similarData.length > 0 && shouldShowDiv" >
            <span>相似组件 ( {{ similarData.length }} )</span>
            <div class="similarbox">
              <div
                class="similar_item"
                v-for="(item, index) in similarData"
                :key="index"
                @click="openSimilarWidget(item._id)">
                <img :src="item.image">
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, inject, provide, computed } from 'vue'
import { post, del, get } from '../utils/request'
import { ElMessage } from 'element-plus'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { fetchTotalCount } from '../utils/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'WidgetPopup',
  props: ['imageData', 'similarData'], // 接收父组件传递的图片数据
  setup (props) {
    const router = useRouter() // 使用 useRouter 获取路由实例
    const store = useStore()
    const { userInfo, getUserInfo } = useUserEffect()
    const currentCollected = ref(false) // 收藏状态
    const isCollected = inject('isCollected', ref(false)) // 接收父组件收藏状态
    const container = ref(null) // 弹窗图片返回顶部
    provide('container', container)
    const route = useRoute()
    const updateCandy = ref(0) // 加载顶部U糖初始值

    const isLoggedIn = () => {
      return localStorage.getItem('isLogin') === 'true' // 登录状态
    }

    // 点击标签搜索
    const searchByKeyword = (keyword) => {
      const route = {
        name: 'search',
        query: { type: 'widget', q: keyword }
      }
      document.body.style.overflow = 'auto'
      // 检查当前路由名称
      const currentRouteName = router.currentRoute.value.name

      if (['app', 'app-hot', 'screen', 'app-all', 'appdetails', 'read'].includes(currentRouteName)) {
        // 获取路由对象的完整 URL
        const url = router.resolve(route).href
        // 在新标签页中打开该 URL
        window.open(url, '_blank')
      } else {
        // 默认跳转
        router.push(route)
        hideSelf()
      }
    }

    // 在收藏页面中不显示相似组件
    const shouldShowDiv = computed(() => {
      return !route.path.includes('/collect')
    })

    // 关闭弹窗
    const { emit } = getCurrentInstance()
    const handleKeyUp = (event) => {
      if (event.key === 'Escape') {
        hideSelf()
      }
    }
    const hideSelf = () => {
      emit('hide')
      // 弹窗图片返回顶部
      if (container.value) {
        container.value.scrollTop = 0
      }
    }

    // 收藏和取消组件
    const toggleCollect = async (itemId) => {
      const isLoggedIn = localStorage.getItem('isLogin') === 'true'
      if (!isLoggedIn) {
        ElMessage({
          message: '请先登录！',
          type: 'warning'
        })
        return
      }
      const userId = userInfo._id
      currentCollected.value = await checkIfCollected(userId, itemId)
      let result
      if (currentCollected.value) {
        result = await removeCollect(userId, itemId)
        fetchTotalCount(userInfo._id, store)
        ElMessage.success('已取消收藏！')
        isCollected.value = false
        emit('update-is-collected', isCollected.value)
      } else {
        result = await post('/api/collect', { userId, widgetId: itemId })
        fetchTotalCount(userInfo._id, store)
        ElMessage.success('收藏成功！')
        isCollected.value = true
        emit('update-is-collected', itemId, isCollected.value)
      }
      if (result?.errno === 0) {
        console.log()
      }
    }
    // 取消收藏组件方法
    const removeCollect = async (userId, itemId) => {
      try {
        const response = await del(`/api/collect/${userId}/${itemId}`)
        if (response.data.errno === 0) {
          return response.data // 返回成功的响应数据
        }
      } catch (error) {
        console.error('取消收藏失败:', error)
        throw error
      }
    }

    // 检查当前数据是否已收藏
    const checkIfCollected = async (userId, itemId) => {
      try {
        const response = await get(`/api/collect/check/${userId}/${itemId}`)
        if (response?.errno === 0 && response?.data) {
          return true
        }
      } catch (error) {
        console.error('检查是否收藏失败:', error.message)
      }
      return false
    }

    // 点击相似组件传递id通知父组件执行弹窗函数
    const openSimilarWidget = (id) => {
      emit('similarWidget', id)
      // 弹窗图片返回顶部
      if (container.value) {
        container.value.scrollTop = 0
      }
    }

    // 页面加载时执行
    onMounted(() => {
      window.addEventListener('keyup', handleKeyUp)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('keyup', handleKeyUp)
    })

    // 重新加载顶部U糖数据
    const reloadCandy = () => {
      updateCandy.value = Math.floor(Math.random() * 100) // 随机数变化更新组件
    }

    // 记录U糖变化
    const createCandyLogBase = async ({ userId, widgetId, type, copyType, candys }) => {
      const currentDate = new Date() // 获取中国北京时间（UTC+8）
      const offset = 8 * 60 // UTC+8 的分钟数
      const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
        .toISOString()
        .replace('Z', '+08:00')
      try {
        const formData = new FormData()
        formData.append('userId', userId)
        formData.append('widgetId', widgetId)
        formData.append('type', type)
        formData.append('copyType', copyType)
        formData.append('candys', candys)
        formData.append('addTime', addTime)
        const result = await post('/api/candyLog', formData)
        if (result?.errno === 0) {
          return
        }
      } catch (e) {
        console.error(e)
      }
    }
    // 记录用户U糖变化
    const createCandyLog = async (imageData) => {
      const userId = userInfo._id
      const widgetId = imageData._id
      const type = '复制组件'
      const copyType = false
      const candys = imageData.price
      await createCandyLogBase({ userId, widgetId, type, copyType, candys })
    }
    // 记录作者U糖变化
    const createCandyLogInauthor = async (imageData) => {
      const authorId = imageData.author?._id
      const widgetId = imageData._id
      const type = '组件被复制'
      const copyType = true
      const candys = imageData.price
      await createCandyLogBase({ userId: authorId, widgetId, type, copyType, candys })
    }

    // 更新作者U糖
    const upCandyInauthor = async (imageData) => {
      try {
        const authorId = imageData.author?._id
        const candyResult = await post(`/api/user/add-candys/${authorId}`, { candys: imageData.price })
        if (candyResult?.errno === 0) {
          return
        }
      } catch (e) {
        console.error(e)
      }
    }

    // 复制组件逻辑
    const copyCodeLarge = async (imageData) => {
      const userId = userInfo._id
      const authorId = imageData.author?._id // 使用可选链操作符检查 author 是否存在

      // 复制代码到剪贴板的通用函数
      const copyToClipboard = (code) => {
        const textarea = document.createElement('textarea')
        textarea.value = code
        textarea.style.position = 'fixed' // 避免在页面上滚动
        textarea.style.opacity = '0'
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }

      //  监测是否为Safari浏览器
      function isSafari () {
        const ua = navigator.userAgent.toLowerCase()
        return ua.includes('safari') && !ua.includes('chrome')
      }
      // 检测是否是 Safari 浏览器
      if (isSafari()) {
        ElMessage.error('当前浏览器暂不支持复制组件！')
        return
      }

      // 验证是否登录
      const isLoggedIn = localStorage.getItem('isLogin') === 'true'
      if (!isLoggedIn) {
        ElMessage.warning('请先登录！每天登录可获得10U糖')
        return
      }

      try {
        // 如果用户是作者，直接复制代码
        if (userId === authorId || imageData.price === 0) {
          const result = await get(`/api/widget/code/${imageData._id}`)
          if (result?.errno === 0) {
            ElMessage.success('组件已复制，请返回设计工具粘贴！')
            copyToClipboard(result.data.code)
            await createCandyLogInauthor(imageData) // 生成作者记录
            await createCandyLog(imageData) // 生成用户记录
          } else {
            ElMessage.error('复制失败!')
          }
        } else {
          // 如果没有作者信息或作者不是当前用户，判断U糖数量
          if (userInfo.candys < imageData.price) {
            ElMessage.error('U糖不足！每天登录可获得10U糖')
            return
          }

          // 扣除用户U糖
          const candyResult = await post(`/api/user/candys/${userId}`, { candys: imageData.price })
          if (candyResult?.errno === 0) {
            await createCandyLogInauthor(imageData) // 生成作者记录
            await createCandyLog(imageData) // 生成用户记录
            await upCandyInauthor(imageData)
            reloadCandy() // 重新加载顶部U糖数据
          } else {
            ElMessage.error('扣除积分失败！')
            return
          }

          // 复制组件代码
          const result = await get(`/api/widget/code/${imageData._id}`)
          if (result?.errno === 0) {
            ElMessage.success('组件已复制，请返回设计工具粘贴！')
            copyToClipboard(result.data.code)
          } else {
            ElMessage.error('复制失败!')
          }
        }
      } catch (error) {
        ElMessage.error('复制组件异常，请稍后重试!')
      }
    }

    const copyUrl = (imageData) => {
      const id = imageData._id
      // 生成路由路径
      const url = router.resolve({ name: 'widgetdetails', params: { id } }).href

      // 自定义的文本
      const customText = '这张来自「UI大师」的组件素材不错哦，点击链接查看 '
      // 拼接 URL 和自定义文本
      const fullText = `${customText}${window.location.origin}${url}`

      // 复制到剪贴板
      navigator.clipboard.writeText(fullText).then(() => {
        ElMessage.success('链接复制成功，可分享给好友了~')
      }).catch((err) => {
        ElMessage.error('复制失败!')
        console.error('复制失败:', err)
      })
    }

    return {
      hideSelf,
      copyCodeLarge,
      userInfo,
      getUserInfo,
      toggleCollect,
      checkIfCollected,
      removeCollect,
      currentCollected,
      isLoggedIn,
      isCollected,
      openSimilarWidget,
      container,
      shouldShowDiv,
      searchByKeyword,
      reloadCandy,
      updateCandy,
      copyUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style/widgetpopup.scss";
</style>
