import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SearchResult from '../views/SearchResult.vue'
import { get } from '@/utils/request'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'UI大师 - 节省80%的设计时间',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载；同时汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: 'UI组件,设计灵感,设计资源,UI素材,高效设计,即时使用,设计灵感,AI设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/:tab?',
    name: 'widget',
    component: () => import('../views/WidgetView.vue'),
    meta: {
      title: 'UI大师组件 - 复制到设计工具使用',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: 'UI组件,设计灵感,设计资源,UI素材,高效设计,即时使用,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/ui/:id?',
    name: 'widgetdetails',
    component: () => import('../views/widget/WidgetDetails.vue'),
    meta: {
      title: 'UI大师 - 组件详情'
    }
  },
  {
    path: '/widget/create',
    name: 'createwidget',
    component: () => import('../views/widget/CreateWidget.vue'),
    meta: {
      title: 'UI大师 - 发布组件'
    }
  },
  {
    path: '/widget/edit/:id?',
    name: 'editwidget',
    component: () => import('../views/widget/EditWidget.vue'),
    meta: {
      title: 'UI大师 - 编辑组件',
      requiresAuth: true // 设置需要登录才能访问的路由
    }
  },
  {
    path: '/search',
    name: 'search',
    component: SearchResult,
    props: route => ({ type: route.query.type, q: route.query.q }),
    meta: {
      title: 'UI大师 - 搜索结果'
    }
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('../views/analysis/Analysis.vue'),
    meta: {
      title: 'UI大师需求分析 - AI帮你梳理设计方向',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载；同时汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: '商业应用,设计灵感,设计资源,UI素材,高效设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/generator',
    name: 'generator',
    component: () => import('../views/AicreaterView.vue'),
    meta: {
      title: 'UI大师UI生成 - 快速搭建UI框架',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载；同时汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: '商业应用,设计灵感,设计资源,UI素材,高效设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/table',
    name: 'table',
    component: () => import(/* webpackChunkName: "ai" */ '../views/table/Table.vue'),
    meta: {
      title: 'UI大师表格生成 - 一键排版',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载；同时汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: '商业应用,设计灵感,设计资源,UI素材,高效设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/collect',
    name: 'collectlist',
    component: () => import(/* webpackChunkName: "collectlist" */ '../views/CollectList.vue'),
    meta: {
      title: 'UI大师 - 我的收藏',
      requiresAuth: true // 设置需要登录才能访问的路由
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'UI大师 - 节省80%的设计时间',
      metaTags: [
        {
          name: 'description',
          content: '丰富的UI组件资源，在线复制使用，无需下载；同时汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，助力设计师、产品经理和程序员高效工作，快速实现创意'
        },
        {
          name: 'keywords',
          content: 'UI组件,设计灵感,设计资源,UI素材,高效设计,即时使用,设计灵感,AI设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    }
  },
  {
    path: '/treaty',
    name: 'treaty',
    component: () => import(/* webpackChunkName: "about" */ '../views/Treaty.vue'),
    meta: {
      title: 'UI大师 - 用户服务协议及隐私政策'
    }
  },
  {
    path: '/:tab?',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
    meta: {
      title: 'UI大师 - 个人中心',
      requiresAuth: true // 设置需要登录才能访问的路由
    },
    children: [
      {
        path: 'me',
        name: 'me',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
        meta: { requiresAuth: true } // 设置需要登录才能访问的路由
      },
      {
        path: 'user',
        name: 'user-profile',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
        meta: { requiresAuth: true } // 设置需要登录才能访问的路由
      },
      {
        path: 'candy',
        name: 'candy',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
        meta: { requiresAuth: true } // 设置需要登录才能访问的路由
      },
      {
        path: 'rule',
        name: 'rule',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
        meta: { requiresAuth: true } // 设置需要登录才能访问的路由
      }
    ]
  },
  {
    path: '/me/:id?',
    name: 'userspace',
    component: () => import(/* webpackChunkName: "store" */ '../components/user/userMe.vue'),
    beforeEnter: (to, from, next) => {
      if (!to.params.id) {
        // 如果 id 为空，则重定向到 '/other-page'
        next({ name: 'me' })
      } else {
        next() // 确保放行到下一个路由
      }
    },
    meta: {
      title: 'UI大师 - 用户主页'
    }
  },
  {
    path: '/store',
    name: 'store',
    component: () => import(/* webpackChunkName: "store" */ '../views/store/Store.vue'),
    meta: {
      title: 'UI大师 - 模板商店'
    }
  },
  {
    path: '/store/deatils/:id?',
    name: 'storedetails',
    component: () => import(/* webpackChunkName: "storedetails" */ '../views/store/StoreDetails.vue'),
    meta: {
      title: 'UI大师 - 模板详情'
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "store" */ '../components/user/ResetPassword.vue'),
    meta: {
      title: 'UI大师 - 重置密码'
    }
  },
  {
    path: '/:tab?',
    name: 'app',
    component: () => import(/* webpackChunkName: "store" */ '../views/idea/Idea.vue'),
    meta: {
      title: 'UI大师灵感库 - 汇集全网商业应用',
      metaTags: [
        {
          name: 'description',
          content: '汇集全网商业应用，匹配UI大师组件，将灵感分秒呈现，在灵感到内容产出环节，大幅缩短设计时间'
        },
        {
          name: 'keywords',
          content: '商业应用,设计灵感,设计资源,UI素材,高效设计,UI设计,视觉设计,APP截图,应用设计,产品设计,UI设计师,UI,UX,截屏,设计网站,设计,灵感'
        }
      ]
    },
    children: [
      {
        path: 'app',
        name: 'app-hot',
        component: () => import(/* webpackChunkName: "user" */ '../views/idea/Idea.vue')
      },
      {
        path: 'screen',
        name: 'screen',
        component: () => import(/* webpackChunkName: "user" */ '../views/idea/Idea.vue')
      },
      {
        path: 'all',
        name: 'app-all',
        component: () => import(/* webpackChunkName: "user" */ '../views/idea/Idea.vue')
      }
    ]
  },
  {
    path: '/app/details/:id?',
    name: 'appdetails',
    component: () => import(/* webpackChunkName: "storedetails" */ '../views/idea/IdeaDetails.vue'),
    meta: {
      title: 'UI大师 - App应用详情'
    }
  },
  {
    path: '/read',
    name: 'read',
    component: () => import(/* webpackChunkName: "store" */ '../views/read/Read.vue'),
    meta: {
      title: 'UI大师经验 - 提升设计思维'
    }
  }
  // {
  //   path: '/demo',
  //   name: 'demo',
  //   component: () => import(/* webpackChunkName: "collectlist" */ '../views/demo.vue'),
  //   meta: {
  //     title: '测试'
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes,

  // 控制路由切换时页面自动回到顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 } // 否则滚动到页面顶部
    }
  }
})

// 动态设置页面标题和元信息
router.afterEach((to) => {
  document.title = to.meta.title || 'UI大师-节省80%的设计时间'

  const metaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"]')
  metaTags.forEach(tag => tag.parentNode.removeChild(tag))

  const newMetaTags = to.meta.metaTags || []
  newMetaTags.forEach(metaTag => {
    const element = document.createElement('meta')
    Object.keys(metaTag).forEach(key => {
      element.setAttribute(key, metaTag[key])
    })
    document.head.appendChild(element)
  })
})

// 导航守卫
router.beforeEach(async (to, from, next) => {
  const isLogin = localStorage.getItem('isLogin')
  if (isLogin) {
    try {
      const response = await get('/api/user/info') // 获取当前用户信息的 API
      const user = response.data

      if (!user.phoneNumber) {
        if (to.path !== '/me') {
          next('/me') // 没有手机号，只能访问个人中心
        } else {
          next() // 如果已经在个人中心页面，则继续访问
        }
      } else {
        // 用户已登录，尝试访问首页时重定向到默认页
        if (to.path === '/home') {
          next('/') // 登录后首页重定向到组件页
        } else {
          next() // 其他情况下，允许访问
        }
      }
    } catch (error) {
      // 获取用户信息失败，可能是 token 失效或网络问题
      localStorage.removeItem('isLogin') // 清除登录状态
      next('/') // 获取用户信息失败
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    next('/') // 未登录，重定向到首页
  } else {
    next() // 不需要登录，继续路由跳转
  }
})

export default router
