// 获取用户信息公共逻辑
import { reactive, onMounted, toRefs, ref } from 'vue'
import { get, post, patch } from '@/utils/request'
import { ElMessage } from 'element-plus'

export const useUserEffect = () => {
  // 获取用户信息--------------------------------------
  const userInfo = reactive({ _id: '', phoneNumber: '', email: '', nickname: '', job: '', imgUrl: '', candys: '', pass: '', analysisPass: '', tablePass: '', about: '', lastLoginTime: '' })
  const getUserInfo = async () => {
    try {
      const result = await get('/api/user/info')
      if (result?.errno === 0 && result?.data) {
        const { _id, phoneNumber, email, nickname, job, imgUrl, candys, pass, analysisPass, tablePass, about, lastLoginTime } = result.data
        userInfo._id = _id || ''
        userInfo.phoneNumber = phoneNumber || ''
        userInfo.email = email || '未绑定邮箱'
        userInfo.nickname = nickname || ''
        userInfo.job = job || ''
        userInfo.imgUrl = imgUrl || ''
        userInfo.candys = candys || '0'
        userInfo.pass = pass || '0'
        userInfo.analysisPass = analysisPass || '0'
        userInfo.tablePass = tablePass || '0'
        userInfo.about = about || '神秘的旅行者'
        userInfo.lastLoginTime = lastLoginTime
      } else {
        localStorage.removeItem('isLogin')
      }
    } catch (error) {
      console.error('获取用户信息失败:', error)
    }
  }
  onMounted(getUserInfo) // 在组件挂载时自动获取用户信息

  // 处理登录逻辑
  const logindata = reactive({ identifier: '', password: '' })
  const handleLogin = async () => {
    try {
      const result = await post('/api/user/login', {
        identifier: logindata.identifier,
        password: logindata.password
      })
      if (result?.errno === 0) {
        await getUserInfo()
        const isFirstLoginToday = result.data.isFirstLoginToday
        if (isFirstLoginToday) {
          await createCandyLog() // 执行日志创建
        }
        window.location.reload() // 刷新页面
        localStorage.isLogin = true
      } else {
        ElMessage.error('账号或密码有误！')
      }
    } catch (e) {
      ElMessage.error('数据请求失败，请稍后重试！')
    }
  }
  const { identifier, password } = toRefs(logindata)

  // 生成日志
  const createCandyLog = async () => {
    const userId = userInfo._id
    const type = '每天登录'
    const copyType = true
    const candys = '10'
    const currentDate = new Date() // 获取当前时间
    // 获取中国北京时间（UTC+8）
    const offset = 8 * 60 // UTC+8 的分钟数
    const addTime = new Date(currentDate.getTime() + offset * 60 * 1000)
      .toISOString()
      .replace('Z', '+08:00')
    try {
      const formData = new FormData()
      formData.append('userId', userId)
      formData.append('type', type)
      formData.append('copyType', copyType)
      formData.append('candys', candys)
      formData.append('addTime', addTime)
      const result = await post('/api/candyLog', formData)
      if (result?.errno === 0) {
        return
      }
    } catch (e) {
      console.error(e)
    }
  }

  // 更新昵称
  const forbiddenWords = ['UI大师', 'ui大师']
  const handleSubmit = async () => {
    if (forbiddenWords.some(word => userInfo.nickname.includes(word))) {
      ElMessage.warning('为了避免混淆，不能包含“UI大师”字符')
      return
    }
    try {
      const id = userInfo._id
      const response = await patch(`/api/user/nickname/${id}`, {
        nickname: userInfo.nickname
      })
      if (response?.errno === 0) {
        ElMessage.success('昵称修改成功！')
        userInfo.nickname = response.data.nickname
        setTimeout(() => {
          window.location.reload()
        }, 1000) // 延迟2秒执行页面刷新
      } else {
        ElMessage.warning('为了避免混淆，不能包含“UI大师”字符')
      }
    } catch (error) {
      console.error('昵称更新失败:', error.message)
    }
  }

  // 更新简介
  const updateAbout = async () => {
    if (forbiddenWords.some(word => userInfo.about.includes(word))) {
      ElMessage.warning('为了避免混淆，不能包含“UI大师”字符')
      return
    }
    try {
      const id = userInfo._id
      const response = await patch(`/api/user/about/${id}`, {
        about: userInfo.about
      })
      if (response?.errno === 0) {
        ElMessage.success('简介修改成功！')
        userInfo.about = response.data.about
        setTimeout(() => {
          window.location.reload()
        }, 1000) // 延迟2秒执行页面刷新
      } else {
        console.error('简介更新失败:', response?.errmsg || '未知错误')
      }
    } catch (error) {
      console.error('简介更新失败:', error.message)
    }
  }

  // 更新头像
  const selectedAvatar = ref(null) // 记录选中的头像的 URL

  const selectAvatar = (imgUrl) => {
    selectedAvatar.value = imgUrl // 更新选中的头像 URL
  }

  const updateAvatar = async () => {
    try {
      const id = userInfo._id
      const response = await patch(`/api/user/avatar/${id}`, {
        imgUrl: selectedAvatar.value
      })
      if (response?.errno === 0) {
        userInfo.imgUrl = response.data.imgUrl
        window.location.reload() // 刷新页面
      } else {
        console.error('头像更新失败:', response?.errmsg || '未知错误')
      }
    } catch (error) {
      console.error('头像更新失败:', error.message)
    }
  }
  const isSelected = (imgUrl) => {
    return imgUrl === selectedAvatar.value // 检查当前图片是否被选择
  }

  // 更新职业
  const jobOptions = ['设计师', '产品经理', '研发工程师', '新媒体运营', '学生', '其他']
  const selectedJob = ref('设计师')

  const updateJob = async () => {
    try {
      const id = userInfo._id
      const response = await patch(`/api/user/job/${id}`, {
        job: selectedJob.value
      })
      if (response?.errno === 0) {
        userInfo.job = response.data.job
        window.location.reload() // 刷新页面
      } else {
        console.error('职业更新失败:', response?.errmsg || '未知错误')
      }
    } catch (error) {
      console.error('职业更新失败:', error.message)
    }
  }

  return {
    userInfo,
    identifier,
    password,
    handleLogin,
    getUserInfo,
    handleSubmit,
    updateAvatar,
    selectAvatar,
    selectedAvatar,
    isSelected,
    updateJob,
    jobOptions,
    selectedJob,
    updateAbout,
    createCandyLog,
    forbiddenWords
  }
}
