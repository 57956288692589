import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueLazyload from 'vue-lazyload'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// createApp(App).use(store).use(router).mount('#app')
// createApp(App).use(store).use(router).use(ElementPlus, { zIndex: 999999999999 }).mount('#app')
const app = createApp(App)
// 全局注册 Element Plus 图标
Object.entries(ElementPlusIconsVue).forEach(([key, component]) => {
  app.component(key, component)
})

app.use(store)
app.use(router)
app.use(ElementPlus, { zIndex: 999999999999 })
app.use(VueLazyload, {
  preLoad: 1.2, // 开始预加载图片的高度比例
  error: '', // 图片加载失败时显示的图片
  loading: '', // 图片加载过程中显示的图片
  attempt: 1 // 尝试加载图片的次数
})

app.mount('#app')
